body {
  background:#000;
  color:#fff;
}

.App {
  text-align: center;
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.teams-input input {
  margin: 10px;
  padding: 5px;
  font-size: 16px;
}

.buttons {
  margin: 20px;
}

.buttons button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
}

.veto {
  max-width: 500px;
  margin: 0 auto;
}

.map-meta {
  position: relative;
    width: 100%;
    height: 100%;
}

.map-meta .map-name {
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  background: black;
  padding: 2px 10px;
  border-radius: 50px;
}

.maps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.map-button {
  margin: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.map-button.ban:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,0) 40%, rgba(255,0,0,1) 100%);
}

.map-button.pick:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgb(0,36,16);
  background: linear-gradient(180deg, rgba(0,36,16,0) 40%, rgb(86 255 46) 100%);
}


.map-button.decider:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgb(0,36,16);
  background: linear-gradient(180deg, rgba(0,36,16,0) 40%, rgb(255, 187, 0) 100%);
}


.map-name {
  font-weight: bold;
}

.team-name {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.veto-order ul {
  list-style-type: none;
  padding: 0;
}

.veto-order li {
  margin: 5px 0;
}

.ban {
  color: red;
}

.pick {
  color: green;
}

.decider {
  color: orange;
}

/* Modal styles */
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.side-buttons button {
  font-size: 16px;
  width: 100%;
  padding: 10px;
  margin: 0;
}

input {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

button {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ReactModalPortal {
  font-family: "Play", sans-serif;
  font-weight: 400;
  font-style: normal;
  color:#000;
}

.side-buttons {
  display: flex;
    align-items: center;
    gap:20px;
    justify-content: space-between;
}